import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ControlContext } from "../contexts/ControlContext";
import { DataContext } from "../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  height: 49px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
`;

const Player = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  height: 100%;
`;

const PlayerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: ${(props) =>
    props.winning ? "rgb(219, 10, 65)" : ""}; */
  height: 100%;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-right: ${(props) =>
    props.winning && props.left ? "4px solid rgb(219, 10, 65)" : ""};
  border-left: ${(props) =>
    props.winning && props.right ? "4px solid rgb(219, 10, 65)" : ""};
`;

const Score = styled.div``;

const MatchNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;

export default function Name({ type, hole, match, scores, course }) {
  const { preview } = useContext(ControlContext);

  function previewGFX({ player, nat }) {
    preview("GOLF-LT-1Line_ANIM", 50, [
      {
        name: "txtPlayer",
        value: {
          text: player,
        },
      },
      {
        name: "txtNAT",
        value: {
          text: nat,
        },
      },
    ]);
  }
  return (
    <Main>
      <Player>
        <Button
          onClick={() => {
            previewGFX({
              player:
                match?.players?.find((p) => p.order === 1)?.player?.first_name +
                " " +
                match?.players?.find((p) => p.order === 1)?.player?.last_name,
              nat: match?.players?.find((p) => p.order === 1)?.player
                ?.nationality,
            });
          }}
        >
          {type}
        </Button>
      </Player>
      <MatchNumber></MatchNumber>
      <Player>
        <Button
          onClick={() => {
            previewGFX({
              player:
                match?.players?.find((p) => p.order === 2)?.player?.first_name +
                " " +
                match?.players?.find((p) => p.order === 2)?.player?.last_name,
              nat: match?.players?.find((p) => p.order === 2)?.player
                ?.nationality,
            });
          }}
        >
          {type}
        </Button>
      </Player>
    </Main>
  );
}
