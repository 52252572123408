import PouchDB from "pouchdb-browser";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { ControlContext } from "./ControlContext";

const DataContext = React.createContext([{}, () => {}]);
const DataProvider = (props) => {
  const [db, setDB] = useState();
  const [holes, setHoles] = useState([]);
  const [course, setCourse] = useState([]);
  const [stats, setStats] = useState([]);
  const [lt_array, setLTArray] = useState([]);
  const [slates, setSlates] = useState([]);
  const [remoteDB, setRemoteDB] = useState();
  const [leftPlayer, setLeftPlayer] = useState();
  const [rightPlayer, setRightPlayer] = useState();
  const [leftScore, setLeftScore] = useState();
  const [rightScore, setRightScore] = useState();
  const [hole, setHole] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const { preview } = useContext(ControlContext);
  useEffect(() => {
    var remote_database = new PouchDB(
      "http://admin:admin@wgc.hyperstudios.live/golf"
    );
    setRemoteDB(remote_database);
    let database = new PouchDB("golf");
    setDB(database);
    database.sync(remote_database, {
      live: true,
    });
    getHoles(database);
    getCourse(database);
    getStats(database);
    getSlates(database);
    getLT(database);
    database
      .get("player:1")
      .then((doc) => {
        setLeftPlayer(doc);
      })
      .catch((err) => {
        console.log(err);
      });
    database
      .get("player:2")
      .then((doc) => {
        setRightPlayer(doc);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (db) {
      db.changes({
        since: "now",
        live: true,
        include_docs: true,
      })
        .on("change", function (change) {
          // change.id contains the doc id, change.doc contains the doc
          if (change.id.indexOf("hole:") === 0) {
            getHoles(db);
          } else if (change.id === "player:1") {
            setLeftPlayer(change.doc);
          } else if (change.id === "player:2") {
            setRightPlayer(change.doc);
          } else if (change.id.indexOf("course:") === 0) {
            getCourse(db);
          } else if (change.id.indexOf("stat:") === 0) {
            getStats(db);
          } else if (change.id.indexOf("slate:") === 0) {
            getSlates(db);
          } else if (change.id.indexOf("LT:") === 0) {
            getLT(db);
          }
        })
        .on("error", function (err) {
          // handle errors
        });
    }
  }, [db]);

  const getHoles = useCallback(
    (database) => {
      database
        .allDocs({
          include_docs: true,
          startkey: "hole:",
          endkey: "hole:\ufff0",
        })
        .then(function (result) {
          // handle result
          setHoles(result.rows.map((row) => row.doc));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    [holes]
  );

  const getCourse = useCallback((database) => {
    database
      .allDocs({
        include_docs: true,
        startkey: "course:",
        endkey: "course:\ufff0",
      })
      .then(function (result) {
        // handle result
        setCourse(result.rows.map((row) => row.doc));
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const getStats = useCallback(
    (database) => {
      database
        .allDocs({
          include_docs: true,
          startkey: "stat:",
          endkey: "stat:\ufff0",
        })
        .then(function (result) {
          // handle result
          setStats(result.rows.map((row) => row.doc));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    [stats]
  );

  const getLT = useCallback(
    (database) => {
      database
        .allDocs({
          include_docs: true,
          startkey: "LT:",
          endkey: "LT:\ufff0",
        })
        .then(function (result) {
          // handle result
          setLTArray(result.rows.map((row) => row.doc));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    [lt_array]
  );

  const getSlates = useCallback(
    (database) => {
      database
        .allDocs({
          include_docs: true,
          startkey: "slate:",
          endkey: "slate:\ufff0",
        })
        .then(function (result) {
          // handle result
          setSlates(result.rows.map((row) => row.doc));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    [stats]
  );

  useEffect(() => {
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < 36 && score > 36 - holeNum) {
        leftScore = Math.abs(score) + "&" + (36 - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > 36) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= 36) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < 36 && Math.abs(score) > 36 - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (36 - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > 36) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= 36) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "";
    }

    if (showPreview) {
      setShowPreview(false);
      preview("GOLF-LT-Match_ANIM", 50, [
        {
          name: "txtPlayer1",
          value: {
            text: leftPlayer?.name,
            visible:
              leftScore == "" || leftScore.indexOf("DN") > -1 ? false : true,
          },
        },
        {
          name: "txtPlayer2",
          value: {
            text: rightPlayer?.name,
            visible:
              rightScore == "" || rightScore.indexOf("DN") > -1 ? false : true,
          },
        },
        {
          name: "txtPlayer1_WHITE",
          value: {
            text: leftPlayer?.name,
            visible:
              leftScore == "" ||
              leftScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? true
                : false,
          },
        },
        {
          name: "txtPlayer2_WHITE",
          value: {
            text: rightPlayer?.name,
            visible:
              rightScore == "" ||
              rightScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? true
                : false,
          },
        },
        {
          name: "txtPlayer1Score",
          value: {
            text:
              leftScore != "A/S" && leftScore.indexOf("DN") == -1
                ? leftScore
                : "",
          },
        },
        {
          name: "txtPlayer1TiedScore",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "imgWhiteStrapP1",
          value: {
            visible:
              leftScore == "" ||
              leftScore.indexOf("DN") > -1 ||
              leftScore === "A/S"
                ? false
                : true,
          },
        },
        {
          name: "imgWhiteStrapP2",
          value: {
            visible:
              rightScore == "" ||
              rightScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? false
                : true,
          },
        },
        {
          name: "imgBlueStrapP1",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "imgBlueStrapP2",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "txtPlayer2Score",
          value: {
            text: rightScore.indexOf("DN") == -1 ? rightScore : "",
          },
        },
        { name: "txtHole", value: { text: holeNum } },
      ]);
    }

    setHole(holeNum);
    setLeftScore(leftScore);
    setRightScore(rightScore);
  }, [holes]);

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <DataContext.Provider
      value={{
        db,
        holes,
        leftPlayer,
        rightPlayer,
        leftScore,
        rightScore,
        hole,
        setShowPreview,
        course,
        stats,
        slates,
        lt_array,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
