import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../contexts/ControlContext";
import { DataContext } from "../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 68%;
  flex-direction: column;
  margin: 1em;
`;

const Row = styled.div`
  display: flex;
  height: 80px;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 150px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin: 0.5em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;

const IdentInput = styled.input`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
  width: 100%;
`;

const SlateInput = styled.textarea`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
  width: 100%;
`;

const IdentRow = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
`;

const SlateRow = styled.div`
  display: flex;
  width: 80%;
  height: 100px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
  margin-left: 50px;
`;

const IdentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.div`
  display: flex;
  width: 120px;
`;

const SlateLabel = styled.div`
  display: flex;
  width: 180px;
`;
export default function Slates() {
  const { preview } = useContext(ControlContext);
  const { db, slates } = useContext(DataContext);

  function changeSlate({ line1, line2, number }) {
    db.get("slate:number:" + number)
      .catch(function (err) {
        if (err.name === "not_found") {
          return {
            _id: "slate:number:" + number,
            number: number,
            line1: line1,
            line2: line2,
          };
        } else {
          // hm, some other error
          throw err;
        }
      })
      .then(function (doc) {
        doc.number = number;
        doc.line1 = line1;
        doc.line2 = line2;

        db.put(doc);
      })
      .catch(function (err) {
        // handle any errors
      });
  }

  function previewGFX({ text }) {
    preview("GOLF-FF-SLATE", 50, [
      {
        name: "Line1",
        value: {
          text: text,
        },
      },
    ]);
  }

  function previewSlateGFX({ line1, line2 }) {
    preview("GOLF-SLATE_ANIM", 50, [
      {
        name: "Line1",
        value: {
          text: line1,
        },
      },
      {
        name: "Line2",
        value: {
          text: line2,
        },
      },
    ]);
  }

  return (
    <Main>
      <Row>
        <Button
          onClick={() => {
            previewGFX({
              text: `WE ARE CURRENTLY EXPERIENCING
TECHNICAL DIFFICULTIES AND
APOLOGISE FOR ANY INCONVENIENCE.

WE'LL BE BACK AS SOON AS POSSIBLE.
`,
            });
          }}
        >
          TECHNICAL ISSUE
        </Button>

        <Slate
          slate={slates.find((s) => s.number === 999)}
          previewGFX={previewGFX}
          number={999}
          changeSlate={changeSlate}
        />
      </Row>
      <Row>
        <IdentContainer>
          <h2>Idents</h2>
          {new Array(5).fill(1).map((item, index) => {
            let slate = slates.find((s) => s.number === index);
            return (
              <Ident
                previewSlateGFX={previewSlateGFX}
                slate={slate}
                number={index}
                changeSlate={changeSlate}
              />
            );
          })}
        </IdentContainer>
      </Row>
    </Main>
  );
}

function Ident({ previewSlateGFX, slate, changeSlate, number }) {
  return (
    <IdentRow>
      <Label>Line 1</Label>{" "}
      <IdentInput
        value={slate?.line1}
        onChange={(e) => {
          changeSlate({
            number: number,
            line1: e.target.value,
            line2: slate?.line2 || "",
          });
        }}
      />
      <Label>Line 2</Label>{" "}
      <IdentInput
        value={slate?.line2}
        onChange={(e) => {
          changeSlate({
            number: number,
            line2: e.target.value,
            line1: slate?.line1 || "",
          });
        }}
      />
      <Button
        onClick={() => {
          previewSlateGFX({
            line1: slate?.line1 || "",
            line2: slate?.line2 || "",
          });
        }}
      >
        Preview
      </Button>
    </IdentRow>
  );
}

function Slate({ previewGFX, slate, changeSlate, number }) {
  return (
    <SlateRow>
      <SlateLabel>Custom text</SlateLabel>{" "}
      <SlateInput
        rows={3}
        value={slate?.line1}
        onChange={(e) => {
          changeSlate({
            number: number,
            line1: e.target.value,
            line2: slate?.line2 || "",
          });
        }}
      />
      <Button
        onClick={() => {
          previewGFX({
            text: slate?.line1 || "",
          });
        }}
      >
        Preview
      </Button>
    </SlateRow>
  );
}
