import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { gql, useMutation } from "@apollo/client";

const UPDATE_MATCH = gql`
  mutation UpdateMatch($_id: String, $holes: JSON) {
    updateMatch(_id: $_id, holes: $holes) {
      _id
      holes {
        number
        status
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #202731;
  padding: 1em;
  box-sizing: border-box;
  margin-top: 5px;
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Player = styled.div`
  display: flex;
  width: 100%;

  padding: 4px;
`;

const Holes = styled.div`
  display: flex;
  margin-left: 50px;
`;

const Hole = styled.div`
  display: flex;
  width: 50px;
  margin-right: 0.5em;
  justify-content: center;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-right: 0.5em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;
const Name = styled.div`
  display: flex;
  min-width: 300px;
`;

const Score = styled.div`
  display: flex;
  margin-left: auto;
`;
function Match({ match, course, start_hole = 0 }) {
  const [matchMutation, { data }] = useMutation(UPDATE_MATCH);
  const [scores, setScores] = useState();
  const [hole, setHole] = useState();

  useEffect(() => {
    getScore(match?.holes, match?.round);
  }, [match]);

  function changeHoleStatus(holeNumber, status) {
    let new_holes = [...(match.holes || [])];
    let index = new_holes.findIndex((h) => h.number === parseInt(holeNumber));
    if (index > -1) {
      new_holes[index].status = status;
    } else {
      new_holes.push({ number: parseInt(holeNumber), status });
    }
    matchMutation({ variables: { _id: match._id, holes: new_holes } });
  }
  function clearHoleStatus(holeNumber) {
    let new_holes = [...(match.holes || [])];
    new_holes = new_holes.filter((h) => h.number !== parseInt(holeNumber));

    matchMutation({ variables: { _id: match._id, holes: new_holes } });
  }
  function getScore(holes, round) {
    let total_holes = round === 3 ? 36 : 18;
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes?.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < total_holes && score > total_holes - holeNum) {
        leftScore = Math.abs(score) + "&" + (total_holes - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < total_holes && Math.abs(score) > total_holes - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (total_holes - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "";
    }
    setScores({ leftScore, rightScore });
    setHole(holeNum);
  }

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  let number_of_holes = match?.round < 3 || start_hole === 18 ? 24 : 18;

  return (
    <Main>
      <Title>Match {match.number}</Title>
      <Player>
        <Name></Name>
        <Holes>
          {new Array(number_of_holes).fill(1)?.map((hole, index) => {
            return <Hole>{start_hole + index + 1}</Hole>;
          })}
        </Holes>
      </Player>
      <Player>
        <Name></Name>

        <Holes>
          {new Array(number_of_holes).fill(1)?.map((_, index) => {
            let hole = course?.holes?.find(
              (h) => h.number === start_hole + index + 1
            );
            return <Hole>{hole?.par}</Hole>;
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          {match?.players?.find((p) => p.order === 1)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 1)?.player?.last_name}
          <Score>{scores?.leftScore}</Score>
        </Name>
        <Holes>
          {new Array(number_of_holes).fill(1)?.map((_, index) => {
            let hole = match?.holes?.find(
              (h) => h.number === start_hole + index + 1
            );
            return (
              <Button
                selected={hole?.status === "player:1"}
                onClick={() => {
                  changeHoleStatus(start_hole + index + 1, "player:1");
                }}
              >
                W
              </Button>
            );
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          <Score>Thru {hole}</Score>
        </Name>
        <Holes>
          {new Array(number_of_holes).fill(1)?.map((_, index) => {
            let hole = match?.holes?.find(
              (h) => h.number === start_hole + index + 1
            );
            return (
              <Button
                selected={hole?.status === "draw"}
                onClick={() => {
                  changeHoleStatus(start_hole + index + 1, "draw");
                }}
              >
                D
              </Button>
            );
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          {match?.players?.find((p) => p.order === 2)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 2)?.player?.last_name}{" "}
          <Score>{scores?.rightScore}</Score>
        </Name>
        <Holes>
          {new Array(number_of_holes).fill(1)?.map((_, index) => {
            let hole = match?.holes?.find(
              (h) => h.number === start_hole + index + 1
            );
            return (
              <Button
                selected={hole?.status === "player:2"}
                onClick={() => {
                  changeHoleStatus(start_hole + index + 1, "player:2");
                }}
              >
                W
              </Button>
            );
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          <Score></Score>
        </Name>
        <Holes>
          {new Array(number_of_holes).fill(1)?.map((_, index) => {
            return (
              <Button
                onClick={() => {
                  clearHoleStatus(start_hole + index + 1);
                }}
              >
                Clr
              </Button>
            );
          })}
        </Holes>
      </Player>
    </Main>
  );
}

export default Match;
