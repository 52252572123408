import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  width: 100%;
  background-color: #202731;
  box-sizing: border-box;
  margin-top: 5px;
  align-items: center;
  height: 100px;
`;

const Player = styled.div`
  display: flex;
  width: 40%;
  padding: 4px;
  height: 100%;
  align-items: center;
`;

const Name = styled.div`
  display: flex;
  min-width: 300px;
`;

const Score = styled.div`
  display: flex;
  min-width: 100px;
  justify-content: center;
`;
const Hole = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
`;
const Winning = styled.div`
  min-width: 8px;
  border-radius: 2px;
  background-color: #c5093b;
  height: 80%;
`;
function Match({ match, course }) {
  const [scores, setScores] = useState();
  const [hole, setHole] = useState();

  useEffect(() => {
    getScore(match?.holes, match?.round);
  }, [match]);

  function getScore(holes, round) {
    let total_holes = round === 3 ? 36 : 18;
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes?.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < total_holes && score > total_holes - holeNum) {
        leftScore = Math.abs(score) + "&" + (total_holes - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < total_holes && Math.abs(score) > total_holes - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (total_holes - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "";
    }
    setScores({ leftScore, rightScore });
    setHole(holeNum);
  }

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <Main>
      <Player style={{ justifyContent: "end" }}>
        <Name style={{ justifyContent: "end" }}>
          {match?.players?.find((p) => p.order === 1)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 1)?.player?.last_name} (
          {match?.players?.find((p) => p.order === 1)?.player?.nationality})
        </Name>
        <Score>{scores?.leftScore}</Score>
        {(scores?.leftScore.indexOf("UP") > -1 ||
          scores?.leftScore.indexOf("&") > -1) && <Winning />}
      </Player>
      <Hole>Thru {hole}</Hole>
      <Player>
        {(scores?.rightScore.indexOf("UP") > -1 ||
          scores?.rightScore.indexOf("&") > -1) && <Winning />}
        <Score>{scores?.rightScore}</Score>
        <Name>
          {match?.players?.find((p) => p.order === 2)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 2)?.player?.last_name} (
          {match?.players?.find((p) => p.order === 2)?.player?.nationality})
        </Name>
      </Player>
    </Main>
  );
}

export default Match;
