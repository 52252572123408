import React, { useContext, useState } from "react";
import Names from "./Names";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
import { ControlContext } from "../contexts/ControlContext";

const Main = styled.div`
  display: flex;
  width: 50%;
`;
const Holes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
`;

const Player = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  height: 100%;
`;

const StatInput = styled.input`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
  width: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 100px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;

export default function LT() {
  const { db, lt_array } = useContext(DataContext);
  const { preview } = useContext(ControlContext);

  function previewGFX({ player }) {
    preview("GOLF-LT-1Line_ANIM", 50, [
      {
        name: "txtPlayer",
        value: {
          text: player,
        },
      },
      {
        name: "txtNAT",
        value: {
          text: "",
        },
      },
    ]);
  }

  function changeLT({ line1, number }) {
    db.get("LT:number:" + number)
      .catch(function (err) {
        if (err.name === "not_found") {
          return {
            _id: "LT:number:" + number,
            number: number,
            line1: line1,
          };
        } else {
          // hm, some other error
          throw err;
        }
      })
      .then(function (doc) {
        doc.number = number;
        doc.line1 = line1;
        db.put(doc);
      })
      .catch(function (err) {
        // handle any errors
      });
  }

  return (
    <Main>
      <Holes>
        <Names />
        {new Array(15).fill(1).map((item, index) => {
          let LT_Data = lt_array.find((s) => s.number === index);
          return (
            <Row key={index}>
              <Player>
                <StatInput
                  value={LT_Data?.line1}
                  onChange={(e) => {
                    console.log(e.target.value);
                    changeLT({
                      line1: e.target.value,
                      number: index,
                    });
                  }}
                />
                <Button
                  onClick={() => {
                    previewGFX({
                      player: LT_Data?.line1,
                    });
                  }}
                >
                  Preview
                </Button>
              </Player>
            </Row>
          );
        })}
      </Holes>
    </Main>
  );
}
