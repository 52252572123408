import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, matchPath, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";

const Main = styled.div`
  width: 130px;
  height: 100vh;
  background-color: #202731;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 20px;
`;

const Hole = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 20px;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    !props.selected ? "rgb(32, 39, 49)" : "rgb(219, 10, 65)"};
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  color: #fff;
  text-decoration: none;
  z-index: 1;
`;

const Splitter = styled.div`
  background-color: #0e1219;
  width: 100%;
  font-size: 12px;
  justify-content: center;
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
`;

function HoleList({ holeId, match }) {
  const [holeData, setHoleData] = useState();
  const { search } = useLocation();
  let holesPlayed = match?.holes?.filter((h) => h.status).length || 0;
  return (
    <Main>
      {new Array(18).fill(1).map((item, index) => {
        let holeNumber = index + Math.max(holesPlayed - 9, 0) + 1;
        return (
          <>
            <Hole
              to={"/match/" + holeNumber + search}
              selected={parseInt(holeId) == holeNumber}
              style={{
                opacity: match?.holes?.find((doc) => doc.number === holeNumber)
                  ?.status
                  ? 0.3
                  : 1,
              }}
            >
              Hole {holeNumber}
            </Hole>
            {holeNumber === 36 && <Splitter>PLAY-OFF</Splitter>}
          </>
        );
      })}
    </Main>
  );
}

export default HoleList;
