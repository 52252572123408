import React from "react";
import styled from "styled-components";
import { MdGolfCourse } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { BsCardText, BsFillTriangleFill } from "react-icons/bs";
import { FaSquareFull } from "react-icons/fa";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";

const Main = styled.div`
  width: 60px;
  height: 100vh;
  background-color: #202731;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  font-size: 20px;
`;

const Button = styled(Link)`
  margin-bottom: 10px;
  display: flex;
  padding: 5px;
  border-radius: 3px;
  background-color: #0e1219;
  width: 36px;
  justify-content: center;
  height: 35px;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.selected ? "rgb(219, 10, 65)" : "#fff")};
  :active {
    color: inherit;
  }
  :hover {
    background-color: rgb(219, 10, 65);
  }
`;
function Sidebar() {
  const { search } = useLocation();
  let history = useHistory();
  const match = matchPath(history.location.pathname, {
    // You can share this string as a constant if you want
    path: "/:view",
  });

  let view = match?.params?.view;

  return (
    <Main>
      <Button to={`/match${search}`} selected={!view || view === "match"}>
        <IoPeopleOutline />
      </Button>
      <Button to="/course" selected={view === "course"}>
        <MdGolfCourse />
      </Button>
      {/* <Button to="/stats" selected={view === "stats"}>
        <BsCardText />
      </Button>
      <Button to="/slates" selected={view === "slates"}>
        <FaSquareFull />
      </Button>
      <Button to="/lt" selected={view === "lt"}>
        <BsFillTriangleFill />
      </Button> */}
    </Main>
  );
}

export default Sidebar;
