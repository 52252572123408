import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Startup from "./Startup";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { UserProvider } from "./contexts/UserContext";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

let wsURI = process.env.REACT_APP_GOLF_API;
wsURI = wsURI.replace("http", "ws");
wsURI = wsURI.replace("https", "wss");

const wsLink = new WebSocketLink({
  uri: `${wsURI}/graphql`,
  options: {
    reconnect: true,
  },
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GOLF_API + "/graphql",
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <UserProvider>
      <Startup />
    </UserProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
