import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import Match from "./Match";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

const Main = styled.div`
  width: 100%;
  display: flex;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const COURSE = gql`
  query GetCourse {
    courses {
      _id
      name
      holes {
        number
        par
        distance
        label
      }
    }
  }
`;

const MATCHES = gql`
  query GetMatches {
    matches {
      _id
      number
      round
      holes {
        number
        status
      }
      players {
        order
        player {
          first_name
          last_name
          nationality
        }
      }
    }
  }
`;

const MATCH_SUBSCRIPTION = gql`
  subscription {
    matchUpdated {
      _id
      number
      round
      holes {
        number
        status
      }
      players {
        order
        player {
          first_name
          last_name
          nationality
        }
      }
    }
  }
`;

const MatchList = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2px;
`;

const MatchItem = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 20px;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    !props.selected ? "rgb(32, 39, 49)" : "rgb(219, 10, 65)"};
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  color: #fff;
  text-decoration: none;
  z-index: 1;
`;

function ScoreEntry() {
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState();
  const [selectedRound, setSelectedRound] = useLocalStorage("round", 1);
  const [getMatches, { data, subscribeToMore }] = useLazyQuery(MATCHES);
  const [course, setCourse] = useState({ holes: [] });
  const [getCourse, { data: course_data }] = useLazyQuery(COURSE);

  useEffect(() => {
    getMatches();
    getCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (course_data) {
      setCourse(course_data.courses[0]);
    }
  }, [course_data]);

  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: MATCH_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        let new_matches = [...matches];
        let index = new_matches.findIndex(
          (m) => m._id === subscriptionData.data.matchUpdated._id
        );

        if (index > -1) {
          new_matches[index] = JSON.parse(
            JSON.stringify(subscriptionData.data.matchUpdated)
          );
        }

        setMatches(new_matches);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [matches]);

  useEffect(() => {
    if (data) {
      setMatches(JSON.parse(JSON.stringify(data.matches)));
    }
  }, [data]);
  return (
    <Main>
      <MatchList>
        <MatchItem
          selected={selectedRound === 1}
          onClick={() => {
            setSelectedRound(1);
          }}
        >
          QF
        </MatchItem>
        <MatchItem
          selected={selectedRound === 2}
          onClick={() => {
            setSelectedRound(2);
          }}
        >
          SF
        </MatchItem>
        <MatchItem
          selected={selectedRound === 3}
          onClick={() => {
            setSelectedRound(3);
          }}
        >
          FINAL
        </MatchItem>
      </MatchList>
      <Column>
        {matches
          ?.filter((m) => m.round === selectedRound)
          ?.map((match) => {
            return <Match match={match} course={course} />;
          })}
        {selectedRound === 3 &&
          matches
            ?.filter((m) => m.round === selectedRound)
            ?.map((match) => {
              return <Match match={match} course={course} start_hole={18} />;
            })}
      </Column>
    </Main>
  );
}

export default ScoreEntry;
