import React, { useContext, useEffect } from "react";
import Login from "./components/Login/Login";
import App from "./App";
import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { UserContext } from "./contexts/UserContext";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PROJECT_SERVER + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
    }
  }
`;

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

function Main() {
  const { setUserToken } = useContext(UserContext);
  const {
    loading,
    error,
    data,
    refetch: refetchLogin,
  } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
    client: user_client,
  });

  useEffect(() => {
    if (data) {
      setUserToken(data.me.token);

      user_client
        .query({
          query: GET_ENGINES,
        })
        .then((response) => {
          window.engines = response.data.enginesForUser.map((e) => ({
            ...e,
            status: "connected",
          }));
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  let isEngine = getParameterByName("engine") === "true";
  if (isEngine) {
    let token = getParameterByName("user-token");
    localStorage.setItem("token", token);
  }

  return (
    <>
      {!isEngine && !data?.me?.token && (
        <Login
          loggedIn={() => {
            refetchLogin();
          }}
        />
      )}
      {(isEngine || data?.me?.token) && <App loggedIn={data?.me} />}
    </>
  );
}

export default function Startup() {
  return <Main />;
}
