import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

const UPDATE_COURSE = gql`
  mutation UpdateCourse($_id: String, $holes: JSON) {
    updateCourse(_id: $_id, holes: $holes) {
      _id
      holes {
        number
        par
        distance
        label
        gender
      }
    }
  }
`;

const COURSE = gql`
  query GetCourse {
    courses {
      _id
      name
      holes {
        number
        par
        distance
        label
        gender
      }
    }
  }
`;

const Main = styled.div`
  height: 100vh;
  background-color: #202731;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 100px;
  font-size: 20px;
`;

const Button = styled.div`
  display: flex;
  height: 32px;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 100px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-right: 0.5em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;
const Buttons = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
function Course() {
  const [course, setCourse] = useState({ holes: [] });
  const [gender, setGender] = useState("male");
  const [getCourse, { data, subscribeToMore }] = useLazyQuery(COURSE);
  const [courseMutation] = useMutation(UPDATE_COURSE, {
    onCompleted: (data) => {
      setCourse(JSON.parse(JSON.stringify(data.updateCourse)));
    },
  });

  const [view, setView] = useState(1);

  useEffect(() => {
    getCourse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setCourse(data.courses[0]);
    }
  }, [data]);
  return (
    <Main>
      {/*<Buttons>
         <Button
          selected={gender === "male"}
          onClick={() => {
            setGender("male");
          }}
        >
          Boys
        </Button>
        <Button
          selected={gender === "female"}
          onClick={() => {
            setGender("female");
          }}
        >
          Girls
        </Button>
      </Buttons> */}
      <Buttons>
        <Button
          selected={view == 1}
          onClick={() => {
            setView(1);
          }}
        >
          1-18
        </Button>
        <Button
          selected={view == 2}
          onClick={() => {
            setView(2);
          }}
        >
          19-36
        </Button>
        <Button
          selected={view == 3}
          onClick={() => {
            setView(3);
          }}
        >
          37-54
        </Button>
      </Buttons>

      {new Array(18).fill(1).map((item, index) => {
        let holeNumber = index + (view - 1) * 18 + 1;
        return (
          <Hole
            onChange={(hole_obj) => {
              let new_holes = [...(course.holes || [])];
              new_holes = new_holes.filter((h) => h.gender);
              let index = new_holes.findIndex(
                (h) =>
                  h.number === parseInt(hole_obj.holeNum) && h.gender === gender
              );

              if (index > -1) {
                new_holes[index] = {
                  number: parseInt(hole_obj.holeNum),
                  par: parseInt(hole_obj.par),
                  distance: parseInt(hole_obj.distance),
                  label: hole_obj.label,
                  gender,
                };
              } else {
                new_holes.push({
                  number: parseInt(hole_obj.holeNum),
                  par: parseInt(hole_obj.par),
                  distance: parseInt(hole_obj.distance),
                  label: hole_obj.label,
                  gender,
                });
              }

              courseMutation({
                variables: { _id: course._id, holes: new_holes },
              });
            }}
            key={holeNumber}
            hole={holeNumber}
            par={
              course?.holes?.find(
                (c) => c.number == holeNumber && c.gender === gender
              )?.par || ""
            }
            distance={
              course?.holes?.find(
                (c) => c.number == holeNumber && c.gender === gender
              )?.distance || ""
            }
            label={
              course?.holes?.find(
                (c) => c.number == holeNumber && c.gender === gender
              )?.label || ""
            }
          />
        );
      })}
    </Main>
  );
}

const HoleMain = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 20px;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    !props.selected ? "rgb(32, 39, 49)" : "rgb(219, 10, 65)"};
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  color: #fff;
  text-decoration: none;
  z-index: 1;
`;

const Input = styled.input`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
  max-width: 50px;
`;

const Column = styled.div`
  margin-left: 1px;
`;
function Hole({ hole, par, distance, label, onChange }) {
  function changeHolePar({ holeNum, par }) {
    onChange({ holeNum, par, distance, label });
  }

  function changeHoleDistance({ holeNum, distance }) {
    onChange({ holeNum, distance, par, label });
  }

  function changeHoleLabel({ holeNum, label }) {
    onChange({ holeNum, label, distance, par });
  }

  return (
    <HoleMain>
      <Column style={{ width: "100px" }}>Hole {hole}</Column>
      <Column>
        Label
        <Input
          value={label}
          onChange={(e) => {
            changeHoleLabel({
              holeNum: hole,
              label: e.currentTarget.value,
            });
          }}
        />
      </Column>
      <Column>
        Par
        <Input
          value={par}
          onChange={(e) => {
            changeHolePar({
              holeNum: hole,
              par: e.currentTarget.value,
            });
          }}
        />
      </Column>
      <Column>
        Distance (yards)
        <Input
          value={distance}
          onChange={(e) => {
            changeHoleDistance({
              holeNum: hole,
              distance: e.currentTarget.value,
            });
          }}
        />
      </Column>
    </HoleMain>
  );
}

export default Course;
