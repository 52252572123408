import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../contexts/ControlContext";

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const Preview = styled.iframe`
  display: flex;
  width: 40%;
  border: none;
  width: 662.069px;
  height: 372.414px;
  background-color: rgb(25, 31, 43);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
`;

const Button = styled.div`
  display: flex;
  height: 80px;
  background-color: ${(props) =>
    props.green ? "#0adb93" : "rgb(219, 10, 65)"};
  color: ${(props) => (props.green ? "#000" : "")};
  width: 200px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-top: 2em;
  :hover {
    background-color: ${(props) => (props.green ? "#0dc585" : "#b80635")};
  }
`;

export default function Engine() {
  const { animate, animateOff, clear } = useContext(ControlContext);

  return (
    <Main>
      <Preview
        src={
          "https://create.hyper.live/preview/a1d300d7-8470-4ba3-8432-d40ed29969eb/626270b19ec0e938e9ad93a9?width=662.0689655172414&height=372.4137931034483"
        }
      ></Preview>
      <Buttons>
        <Button green onClick={() => animate()}>
          Animate
        </Button>
        <Button onClick={() => animateOff()}>Animate Off</Button>
        <Button
          style={{ backgroundColor: "rgb(32 39 49)" }}
          onClick={() => clear()}
        >
          CLEAR ALL
        </Button>
      </Buttons>
    </Main>
  );
}
