import { gql, useMutation } from "@apollo/client";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import styled from "styled-components";
import { ControlContext } from "../contexts/ControlContext";

const UPDATE_MATCH = gql`
  mutation UpdateMatch($_id: String, $holes: JSON) {
    updateMatch(_id: $_id, holes: $holes) {
      _id
      holes {
        number
        status
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
`;

const Player = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  height: 100%;
`;

const PlayerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: ${(props) =>
    props.winning ? "rgb(219, 10, 65)" : ""}; */
  height: 100%;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-right: ${(props) =>
    props.winning && props.left ? "4px solid rgb(219, 10, 65)" : ""};
  border-left: ${(props) =>
    props.winning && props.right ? "4px solid rgb(219, 10, 65)" : ""};
`;

const Score = styled.div``;

const Name = styled.div`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
`;

const MatchNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;

function Hole({ holeNumber, match, setShowPreview }) {
  const [holeData, setHoleData] = useState();
  const [matchMutation, { data }] = useMutation(UPDATE_MATCH, {
    onCompleted: (data) => {
      setHoleData(
        JSON.parse(
          JSON.stringify(
            data.updateMatch?.holes?.find(
              (doc) => doc.number === parseInt(holeNumber)
            )
          )
        )
      );
    },
  });

  useEffect(() => {
    if (match) {
      setHoleData(
        match?.holes?.find((doc) => doc.number === parseInt(holeNumber))
      );
    }
  }, [match, holeNumber]);

  function changeHoleStatus(status) {
    setShowPreview(true);
    let new_holes = [...(match.holes || [])];
    let index = new_holes.findIndex((h) => h.number === parseInt(holeNumber));
    if (index > -1) {
      new_holes[index].status = status;
    } else {
      new_holes.push({ number: parseInt(holeNumber), status });
    }
    matchMutation({ variables: { _id: match._id, holes: new_holes } });
  }

  return (
    <Main>
      <Player>
        <Button
          onClick={() => {
            changeHoleStatus("player:1");
          }}
          selected={holeData?.status === "player:1"}
        >
          Won Hole
        </Button>
      </Player>
      <MatchNumber>
        <Button
          onClick={() => {
            changeHoleStatus("draw");
          }}
          selected={holeData?.status === "draw"}
        >
          Draw
        </Button>
      </MatchNumber>
      <Player>
        <Button
          onClick={() => {
            changeHoleStatus("player:2");
          }}
          selected={holeData?.status === "player:2"}
        >
          Won Hole
        </Button>
      </Player>
      <Player style={{ width: "80px" }}>
        <Button
          onClick={() => {
            changeHoleStatus();
          }}
        >
          Clear
        </Button>
      </Player>
    </Main>
  );
}

export default Hole;
