import React, { useState, useEffect, useContext } from "react";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import Hole from "./Hole";
import HoleList from "./HoleList";
import Names from "./Names";
import styled from "styled-components";
import Shot from "./Shot";
import HoleIdent from "./HoleIdent";
import { ControlContext } from "../contexts/ControlContext";
import Name from "./Name";

const Main = styled.div`
  display: flex;
  width: 68%;
`;
const Holes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
`;
export default function Match({ match, course, players }) {
  useLocation();
  let history = useHistory();
  let matcher = useRouteMatch();
  const holeid_match = matchPath(history.location.pathname, {
    // You can share this string as a constant if you want
    path: "/match/:holeId",
  });
  let holeId = holeid_match?.params?.holeId;

  const [scores, setScores] = useState();
  const [hole, setHole] = useState();
  const [showPreview, setShowPreview] = useState();
  const { preview } = useContext(ControlContext);
  useEffect(() => {
    getScore(match.holes, match?.round);
  }, [match]);

  function getScore(holes, round) {
    let total_holes = round === 3 ? 36 : 18;
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes?.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < total_holes && score > total_holes - holeNum) {
        leftScore = Math.abs(score) + "&" + (total_holes - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < total_holes && Math.abs(score) > total_holes - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (total_holes - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "";
    }
    if (showPreview) {
      setShowPreview(false);
      preview("GOLF-LT-Match_ANIM", 50, [
        {
          name: "txtPlayer1",
          value: {
            text: match?.players?.find((p) => p.order === 1)?.player?.last_name,
            visible:
              leftScore == "" || leftScore.indexOf("DN") > -1 ? false : true,
          },
        },
        {
          name: "txtPlayer2",
          value: {
            text: match?.players?.find((p) => p.order === 2)?.player?.last_name,
            visible:
              rightScore == "" || rightScore.indexOf("DN") > -1 ? false : true,
          },
        },
        {
          name: "txtPlayer1_WHITE",
          value: {
            text: match?.players?.find((p) => p.order === 1)?.player?.last_name,
            visible:
              leftScore == "" ||
              leftScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? true
                : false,
          },
        },
        {
          name: "txtPlayer2_WHITE",
          value: {
            text: match?.players?.find((p) => p.order === 2)?.player?.last_name,
            visible:
              rightScore == "" ||
              rightScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? true
                : false,
          },
        },
        {
          name: "txtPlayer1Score",
          value: {
            text:
              leftScore != "A/S" && leftScore.indexOf("DN") == -1
                ? leftScore
                : "",
          },
        },
        {
          name: "txtPlayer1TiedScore",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "imgWhiteStrapP1",
          value: {
            visible:
              leftScore == "" ||
              leftScore.indexOf("DN") > -1 ||
              leftScore === "A/S"
                ? false
                : true,
          },
        },
        {
          name: "imgWhiteStrapP2",
          value: {
            visible:
              rightScore == "" ||
              rightScore.indexOf("DN") > -1 ||
              leftScore == "A/S"
                ? false
                : true,
          },
        },
        {
          name: "imgBlueStrapP1",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "imgBlueStrapP2",
          value: {
            visible: leftScore == "A/S" ? true : false,
          },
        },
        {
          name: "txtPlayer2Score",
          value: {
            text: rightScore.indexOf("DN") == -1 ? rightScore : "",
          },
        },
        { name: "txtHole", value: { text: holeNum } },
      ]);
    }

    setScores({ leftScore, rightScore });
    setHole(holeNum);
  }

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <Main>
      <Holes>
        <Names match={match} scores={scores} hole={hole} players={players} />
        <Row>
          <HoleList holeId={holeId} match={match} />

          <Switch>
            <Route path={`${matcher.path}/:holeId`}>
              <Holes>
                <HoleIdent
                  holeId={holeId}
                  course={course}
                  scores={scores}
                  hole={hole}
                  match={match}
                >
                  Hole Ident
                </HoleIdent>
                <Name type={"Name"} match={match} />
                <Shot
                  type={"Tee Shot"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"2nd Shot"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"3rd Shot"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"4th Shot"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"5th Shot"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Eagle Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Birdie Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Par Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Bogey Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Double Bogey Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"Triple Bogey Putt"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"To Win Hole"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"To Halve Hole"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"To Win Match"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Shot
                  type={"To Halve Match"}
                  hole={holeId}
                  match={match}
                  course={course}
                  scores={scores}
                />
                <Hole
                  holeNumber={holeId}
                  match={match}
                  setShowPreview={setShowPreview}
                />
              </Holes>
            </Route>

            <Route path={matcher.path}>
              <Holes>
                <h3 style={{ margin: "1em" }}>Please select a hole.</h3>
              </Holes>
            </Route>
          </Switch>
        </Row>
      </Holes>
    </Main>
  );
}
