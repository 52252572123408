import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #202731;
  padding: 1em;
  box-sizing: border-box;
  margin-top: 5px;
  margin-left: 10px;
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Player = styled.div`
  display: flex;
  width: 100%;

  padding: 4px;
`;

const Holes = styled.div`
  display: flex;
  margin-left: 50px;
`;

const Hole = styled.div`
  display: flex;
  width: 30px;
  margin-right: 0.5em;
  justify-content: center;
`;

const Button = styled.div`
  display: flex;
  height: 100%;

  width: 30px;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-right: 0.5em;
`;
const Name = styled.div`
  display: flex;
  min-width: 300px;
`;

const Score = styled.div`
  display: flex;
  margin-left: auto;
`;
function Scorecard({ match, course }) {
  const [scores, setScores] = useState();
  const [hole, setHole] = useState();

  useEffect(() => {
    getScore(match?.holes, match?.round);
  }, [match]);

  function getScore(holes, round) {
    let total_holes = round === 3 ? 36 : 18;
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes?.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < total_holes && score > total_holes - holeNum) {
        leftScore = Math.abs(score) + "&" + (total_holes - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < total_holes && Math.abs(score) > total_holes - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (total_holes - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "";
    }
    setScores({ leftScore, rightScore });
    setHole(holeNum);
  }

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <Main>
      <Player>
        <Name></Name>
        <Holes>
          {new Array(18).fill(1)?.map((hole, index) => {
            return <Hole>{index + 1}</Hole>;
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          {match?.players?.find((p) => p.order === 1)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 1)?.player?.last_name}
          <Score>{scores?.leftScore}</Score>
        </Name>
        <Holes>
          {match?.holes?.map((score, index) => {
            let score_total = match?.holes?.reduce((prev, current) => {
              if (score.number >= current.number) {
                if (current.status === "player:1") {
                  return prev + 1;
                } else if (current.status === "player:2") {
                  return prev - 1;
                }
              }
              return prev;
            }, 0);
            return <Button>{score_total > 0 ? score_total + "UP" : ""}</Button>;
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          <Score>Thru {hole}</Score>
        </Name>
        <Holes>
          {match?.holes?.map((score, index) => {
            let score_total = match?.holes?.reduce((prev, current) => {
              if (score.number >= current.number) {
                if (current.status === "player:1") {
                  return prev + 1;
                } else if (current.status === "player:2") {
                  return prev - 1;
                }
              }
              return prev;
            }, 0);
            return <Button>{score_total === 0 ? "A/S" : ""}</Button>;
          })}
        </Holes>
      </Player>
      <Player>
        <Name>
          {match?.players?.find((p) => p.order === 2)?.player?.first_name}{" "}
          {match?.players?.find((p) => p.order === 2)?.player?.last_name}{" "}
          <Score>{scores?.rightScore}</Score>
        </Name>
        <Holes>
          {match?.holes?.map((score, index) => {
            let score_total = match?.holes?.reduce((prev, current) => {
              if (score.number >= current.number) {
                if (current.status === "player:2") {
                  return prev + 1;
                } else if (current.status === "player:1") {
                  return prev - 1;
                }
              }
              return prev;
            }, 0);
            return <Button>{score_total > 0 ? score_total + "UP" : ""}</Button>;
          })}
        </Holes>
      </Player>
    </Main>
  );
}

export default Scorecard;
