import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ControlContext } from "../contexts/ControlContext";
import { DataContext } from "../contexts/DataContext";

const Main = styled.div`
  display: flex;
  width: 100%;
  height: 49px;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
`;

const Player = styled.div`
  display: flex;
  width: 30%;
  justify-content: center;
  height: 100%;
`;

const MatchNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 100%;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-left: 1em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;

export default function HoleIdent({ holeId, course, scores, hole, match }) {
  const { preview } = useContext(ControlContext);
  const [holeObj, setHoleObj] = useState();

  useEffect(() => {
    if (course) {
      setHoleObj(
        course?.holes?.find(
          (c) =>
            c.number == holeId &&
            c.gender === match?.players?.[0]?.player?.gender
        )
      );
    }
  }, [course, holeId, match]);

  return (
    <Main>
      <Player>
        Hole {holeObj?.label} - Par {holeObj?.par} - {holeObj?.distance} yds
      </Player>
      <MatchNumber>
        <Button
          onClick={() => {
            preview("GOLF-FLYBY_ANIM", 50, [
              {
                name: "txtHole",
                value: {
                  text: holeObj?.label,
                },
              },
              {
                name: "txtPar",
                value: {
                  text: "PAR " + holeObj?.par,
                },
              },
              {
                name: "txtDist",
                value: {
                  text: holeObj?.distance + " yds",
                },
              },
            ]);
          }}
        >
          HOLE IDENT
        </Button>
      </MatchNumber>
      <MatchNumber>
        <Button
          onClick={() => {
            preview("GOLF-LT-Match_ANIM", 50, [
              {
                name: "txtPlayer1",
                value: {
                  text: match?.players?.find((p) => p.order === 1)?.player
                    ?.last_name,
                  visible:
                    scores?.leftScore == "" ||
                    scores?.leftScore.indexOf("DN") > -1
                      ? false
                      : true,
                },
              },
              {
                name: "txtPlayer2",
                value: {
                  text: match?.players?.find((p) => p.order === 2)?.player
                    ?.last_name,
                  visible:
                    scores?.rightScore == "" ||
                    scores?.rightScore.indexOf("DN") > -1
                      ? false
                      : true,
                },
              },
              {
                name: "txtPlayer1_WHITE",
                value: {
                  text: match?.players?.find((p) => p.order === 1)?.player
                    ?.last_name,
                  visible:
                    scores?.leftScore == "" ||
                    scores?.leftScore.indexOf("DN") > -1 ||
                    scores?.leftScore == "A/S"
                      ? true
                      : false,
                },
              },
              {
                name: "txtPlayer2_WHITE",
                value: {
                  text: match?.players?.find((p) => p.order === 2)?.player
                    ?.last_name,
                  visible:
                    scores?.rightScore == "" ||
                    scores?.rightScore.indexOf("DN") > -1 ||
                    scores?.leftScore == "A/S"
                      ? true
                      : false,
                },
              },
              {
                name: "txtPlayer1Score",
                value: {
                  text:
                    hole > 0
                      ? scores?.leftScore != "A/S" &&
                        scores?.leftScore.indexOf("DN") == -1
                        ? scores?.leftScore
                        : ""
                      : "",
                },
              },
              {
                name: "txtPlayer1TiedScore",
                value: {
                  visible:
                    hole > 0 && scores?.leftScore == "A/S" ? true : false,
                },
              },

              {
                name: "imgWhiteStrapP1",
                value: {
                  visible:
                    scores?.leftScore == "" ||
                    scores?.leftScore.indexOf("DN") > -1 ||
                    scores?.leftScore === "A/S"
                      ? false
                      : true,
                },
              },
              {
                name: "imgWhiteStrapP2",
                value: {
                  visible:
                    scores?.rightScore == "" ||
                    scores?.rightScore.indexOf("DN") > -1 ||
                    scores?.leftScore == "A/S"
                      ? false
                      : true,
                },
              },
              {
                name: "imgBlueStrapP1",
                value: {
                  visible: scores?.leftScore == "A/S" ? true : false,
                },
              },
              {
                name: "imgBlueStrapP2",
                value: {
                  visible: scores?.leftScore == "A/S" ? true : false,
                },
              },
              {
                name: "txtPlayer2Score",
                value: {
                  text:
                    hole > 0
                      ? scores?.rightScore.indexOf("DN") == -1
                        ? scores?.rightScore
                        : ""
                      : "",
                },
              },
              { name: "txtHole", value: { text: hole === 0 ? "V" : hole } },
            ]);
          }}
        >
          CURRENT MATCH SCORE
        </Button>
      </MatchNumber>
    </Main>
  );
}
