import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Match from "./Match";
import { Link } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const MatchList = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2px;
`;

const MatchItem = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 20px;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    !props.selected ? "rgb(32, 39, 49)" : "rgb(219, 10, 65)"};
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  color: #fff;
  text-decoration: none;
  z-index: 1;
`;

function Matches({ matches, course, players }) {
  const [selectedMatch, setSelectedMatch] = useState();
  const [selectedRound, setSelectedRound] = useLocalStorage("round", 1);

  return (
    <Main>
      <MatchList>
        <MatchItem
          selected={selectedRound === 1}
          onClick={() => {
            setSelectedRound(1);
          }}
        >
          QF
        </MatchItem>
        <MatchItem
          selected={selectedRound === 2}
          onClick={() => {
            setSelectedRound(2);
          }}
        >
          SF
        </MatchItem>
        <MatchItem
          selected={selectedRound === 3}
          onClick={() => {
            setSelectedRound(3);
          }}
        >
          FINAL
        </MatchItem>
      </MatchList>
      <MatchList>
        {matches
          ?.filter((m) => m.round === selectedRound)
          ?.map((match) => {
            return (
              <MatchItem
                selected={match._id === selectedMatch}
                onClick={() => {
                  setSelectedMatch(match._id);
                }}
              >
                Match {match.number}
              </MatchItem>
            );
          })}
      </MatchList>
      {selectedMatch && (
        <Match
          match={matches.find((m) => m._id === selectedMatch)}
          course={course}
          players={players}
        />
      )}
    </Main>
  );
}

export default Matches;
