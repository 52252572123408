import { gql, useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../contexts/DataContext";
const UPDATE_MATCH = gql`
  mutation UpdateMatch($_id: String, $players: JSON) {
    updateMatch(_id: $_id, players: $players) {
      _id
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(32, 39, 49);
  border-bottom: 2px solid rgb(24, 29, 37);
  opacity: ${(props) => (props.finished ? 0.7 : 1)};
  z-index: 1;
  padding-left: 100px;
`;

const Player = styled.div`
  display: flex;
  width: 50%;
  height: 31px;
  justify-content: center;
  align-items: center;
`;

const PlayerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: ${(props) =>
    props.winning ? "rgb(219, 10, 65)" : ""}; */
  height: 100%;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  border-right: ${(props) =>
    props.winning && props.left ? "4px solid rgb(219, 10, 65)" : ""};
  border-left: ${(props) =>
    props.winning && props.right ? "4px solid rgb(219, 10, 65)" : ""};
`;

const Score = styled.div``;

const Name = styled.input`
  /* min-width: 70%; */
  text-align: ${(props) => (props.left ? "right" : "left")};
  margin-left: 2em;
  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
`;

const Nat = styled.input`
  /* min-width: 70%; */
  max-width: 50px;
  text-align: ${(props) => (props.left ? "right" : "left")};

  margin-right: 2em;
  background-color: #0e1219;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border: none;
  color: #fff;
`;

const MatchNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 35%;
`;

const Dropown = styled.select`
  width: 90%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
  margin-right: 1em;
  margin-left: 1em;
`;
const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
  height: 15px;
`;
function Names({ players, match, scores, hole }) {
  const [matchMutation, { data }] = useMutation(UPDATE_MATCH);

  return (
    <Main>
      <Player>
        <Dropown
          onChange={(e) => {
            let new_players = [...(match.players || [])]?.filter(
              (p) => p.order !== 1
            );
            new_players.push({ player: e.target.value, order: 1 });
            matchMutation({
              variables: { _id: match._id, players: new_players },
            });
          }}
          value={
            match?.players?.find((p) => p.order === 1)?.player?._id || "none"
          }
        >
          <DropownOption disabled value={"none"}>
            -- select player --
          </DropownOption>
          {players?.map((player) => {
            return (
              <DropownOption value={player?._id}>
                {player.first_name} {player.last_name} ({player.nationality})
              </DropownOption>
            );
          })}
        </Dropown>

        {scores?.leftScore}
      </Player>
      <MatchNumber>Thru {hole}</MatchNumber>
      <Player>
        {scores?.rightScore}
        <Dropown
          onChange={(e) => {
            let new_players = [...(match.players || [])]?.filter(
              (p) => p.order !== 2
            );
            new_players.push({ player: e.target.value, order: 2 });
            matchMutation({
              variables: { _id: match._id, players: new_players },
            });
          }}
          value={
            match?.players?.find((p) => p.order === 2)?.player?._id || "none"
          }
        >
          <DropownOption disabled value={"none"}>
            -- select player --
          </DropownOption>
          {players?.map((player) => {
            return (
              <DropownOption
                value={player?._id}
                selected={
                  (match?.players?.find((p) => p.order === 2)?.player?._id ||
                    "") === player?._id
                }
              >
                {player.first_name} {player.last_name} ({player.nationality})
              </DropownOption>
            );
          })}
        </Dropown>
      </Player>
    </Main>
  );
}

export default Names;
