import "./App.css";
import styled from "styled-components";
import { ControlContext, ControlProvider } from "./contexts/ControlContext";
import Sidebar from "./components/Sidebar";
import { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Course from "./components/Course";
import Match from "./components/Match";
import Engine from "./components/Engine";
import Stats from "./components/Stats";
import Slates from "./components/Slates";
import { UserProvider } from "./contexts/UserContext";
import LT from "./components/LT";
import Header from "./components/Header/Header";
import Matches from "./components/Matches";
import ScoreEntry from "./components/ScoreEntry/ScoreEntry";
import Commentators from "./components/Commentators/Commentators";
import { gql, useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

const COURSE = gql`
  query GetCourse {
    courses {
      _id
      name
      holes {
        number
        par
        distance
        label
        gender
      }
    }
  }
`;

const MATCHES = gql`
  query GetMatches {
    matches {
      _id
      number
      round
      holes {
        number
        status
      }
      players {
        order
        player {
          _id
          first_name
          last_name
          nationality
          gender
        }
      }
    }
  }
`;

const PLAYERS = gql`
  query GetPlayers {
    players {
      _id
      first_name
      last_name
      nationality
    }
  }
`;

const MATCH_SUBSCRIPTION = gql`
  subscription {
    matchUpdated {
      _id
      number
      round
      holes {
        number
        status
      }
      players {
        order
        player {
          _id
          first_name
          last_name
          nationality
        }
      }
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Button = styled.div`
  display: flex;
  height: 80px;
  background-color: ${(props) =>
    props.selected ? "rgb(219, 10, 65)" : "rgb(24, 29, 37)"};
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin: 1em;
  :hover {
    background-color: ${(props) =>
      props.selected ? "rgb(219, 10, 65)" : "#b80635"};
  }
`;
const Buttons = styled.div`
  display: flex;
  margin-top: 100px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  window.command_server =
    getParameterByName("command_server") ||
    process.env.REACT_APP_COMMAND_SERVER;
  let hide_header = getParameterByName("show_header") === "false";

  return (
    <div className="App">
      <ControlProvider>
        <Router>
          {!hide_header && <Header />}
          <MainUI />
        </Router>
      </ControlProvider>
    </div>
  );
}

function MainUI() {
  const [matches, setMatches] = useState([]);
  const [players, setPlayers] = useState([]);
  const [getMatches, { data, subscribeToMore }] = useLazyQuery(MATCHES);
  const [course, setCourse] = useState({ holes: [] });
  const [getCourse, { data: course_data }] = useLazyQuery(COURSE);
  const [getPlayers, { data: players_data }] = useLazyQuery(PLAYERS);
  const { preview } = useContext(ControlContext);
  const { search } = useLocation();
  useEffect(() => {
    getMatches();
    getCourse();
    getPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (course_data) {
      setCourse(course_data.courses[0]);
    }
  }, [course_data]);

  useEffect(() => {
    if (players_data) {
      setPlayers(players_data.players);
    }
  }, [players_data]);

  useEffect(() => {
    let unsubscribe = subscribeToMore({
      document: MATCH_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        debugger;
        let new_matches = [...matches];
        let index = new_matches.findIndex(
          (m) => m._id === subscriptionData.data.matchUpdated._id
        );

        if (index > -1) {
          new_matches[index] = JSON.parse(
            JSON.stringify(subscriptionData.data.matchUpdated)
          );
        }

        setMatches(new_matches);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [matches]);

  useEffect(() => {
    if (data) {
      setMatches(JSON.parse(JSON.stringify(data.matches)));
    }
  }, [data]);

  function getScore(holes, round) {
    let total_holes = round === 3 ? 36 : 18;
    let score = 0;
    let holeNum = 0;
    let leftScore = "";
    let rightScore = "";
    holes?.map((hole) => {
      if (hole.status === "player:1") {
        score++;
        holeNum++;
      } else if (hole.status === "player:2") {
        score--;
        holeNum++;
      } else if (hole.status) {
        holeNum++;
      }
    });

    if (score > 0) {
      if (holeNum < total_holes && score > total_holes - holeNum) {
        leftScore = Math.abs(score) + "&" + (total_holes - holeNum);
        rightScore = "";
        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = ordinal(holeNum);
          rightScore = "";
        } else {
          leftScore = Math.abs(score) + "UP";
          rightScore = Math.abs(score) + "DN";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else if (score < 0) {
      if (holeNum < total_holes && Math.abs(score) > total_holes - holeNum) {
        leftScore = "";
        rightScore = Math.abs(score) + "&" + (total_holes - holeNum);

        holeNum = "F";
      } else {
        if (holeNum > total_holes) {
          leftScore = "";
          rightScore = ordinal(holeNum);
        } else {
          leftScore = Math.abs(score) + "DN";
          rightScore = Math.abs(score) + "UP";
        }
        if (holeNum >= total_holes) {
          holeNum = "F";
        }
      }
    } else {
      leftScore = "A/S";
      rightScore = "A/S";
    }
    if (holeNum === 0) {
      holeNum = "V";
      leftScore = "";
      rightScore = "";
    }

    return { leftScore, rightScore, holeNum };
  }

  function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <Main>
      <Switch>
        <Route path="/commentators">
          <Commentators />
        </Route>
        <Route path="/score_entry">
          <ScoreEntry />
        </Route>
        <Route path="/*">
          <Sidebar />
          <Switch>
            <Route path="/course">
              <Course />
            </Route>
            <Route path="/match">
              <Matches matches={matches} course={course} players={players} />
            </Route>
            <Route path="/stats">
              <Stats />
            </Route>
            <Route path="/slates">
              <Slates />
            </Route>
            <Route path="/lt">
              <LT />
            </Route>

            <Route path="/">
              <Redirect to={`/match${search}`} />
            </Route>
          </Switch>
          <Column>
            <Engine />
            <Buttons>
              <Button
                onClick={() => {
                  let data = [];
                  matches
                    ?.filter((m) => m.round === 1)
                    .forEach((match, index) => {
                      let scores = getScore(match.holes, match.round);
                      let row = index + 1;
                      data.push({
                        name: "HOME_BG_" + row,
                        value: {
                          visible:
                            scores.leftScore.indexOf("DN") === -1 &&
                            scores.leftScore !== "" &&
                            scores.leftScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "AWAY_BG_" + row,
                        value: {
                          visible:
                            scores.rightScore.indexOf("DN") === -1 &&
                            scores.rightScore !== "" &&
                            scores.rightScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "HOLE_" + row,
                        value: { text: scores.holeNum },
                      });
                      data.push({
                        name: "HOME_SCORE_" + row,
                        value: {
                          text: scores.leftScore,
                          visible: scores.leftScore.indexOf("DN") === -1,
                          colour:
                            scores.leftScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_SCORE_" + row,
                        value: {
                          text: scores.rightScore,
                          visible: scores.rightScore.indexOf("DN") === -1,
                          colour:
                            scores.rightScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });

                      data.push({
                        name: "HOME_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.first_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "HOME_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.last_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.first_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.last_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                    });
                  preview("GOLF-FF-TABLE-X4", 50, data);
                }}
              >
                QF LB
              </Button>

              <Button
                onClick={() => {
                  let data = [];
                  matches
                    ?.filter((m) => m.round === 2)
                    ?.forEach((match, index) => {
                      let scores = getScore(match.holes, match.round);
                      let row = index + 1;
                      data.push({
                        name: "HOME_BG_" + row,
                        value: {
                          visible:
                            scores.leftScore.indexOf("DN") === -1 &&
                            scores.leftScore !== "" &&
                            scores.leftScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "AWAY_BG_" + row,
                        value: {
                          visible:
                            scores.rightScore.indexOf("DN") === -1 &&
                            scores.rightScore !== "" &&
                            scores.rightScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "HOLE_" + row,
                        value: { text: scores.holeNum },
                      });
                      data.push({
                        name: "HOME_SCORE_" + row,
                        value: {
                          text: scores.leftScore,
                          visible: scores.leftScore.indexOf("DN") === -1,
                          colour:
                            scores.leftScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_SCORE_" + row,
                        value: {
                          text: scores.rightScore,
                          visible: scores.rightScore.indexOf("DN") === -1,
                          colour:
                            scores.rightScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });

                      data.push({
                        name: "HOME_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.first_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "HOME_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.last_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.first_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.last_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                    });
                  preview("GOLF-FF-TABLE-X2", 50, data);
                }}
              >
                SF LB
              </Button>
              <Button
                onClick={() => {
                  let data = [];
                  matches
                    ?.filter((m) => m.round === 3)
                    ?.forEach((match, index) => {
                      let scores = getScore(match.holes, match.round);
                      let row = index + 1;
                      data.push({
                        name: "HOME_BG_" + row,
                        value: {
                          visible:
                            scores.leftScore.indexOf("DN") === -1 &&
                            scores.leftScore !== "" &&
                            scores.leftScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "AWAY_BG_" + row,
                        value: {
                          visible:
                            scores.rightScore.indexOf("DN") === -1 &&
                            scores.rightScore !== "" &&
                            scores.rightScore !== "A/S",
                        },
                      });
                      data.push({
                        name: "HOLE_" + row,
                        value: { text: scores.holeNum },
                      });
                      data.push({
                        name: "HOME_SCORE_" + row,
                        value: {
                          text: scores.leftScore,
                          visible: scores.leftScore.indexOf("DN") === -1,
                          colour:
                            scores.leftScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_SCORE_" + row,
                        value: {
                          text: scores.rightScore,
                          visible: scores.rightScore.indexOf("DN") === -1,
                          colour:
                            scores.rightScore.indexOf("A/S") > -1
                              ? "#14E8FF"
                              : "#041E42",
                        },
                      });

                      data.push({
                        name: "HOME_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.first_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "HOME_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 1)?.player
                              ?.last_name || "",
                          colour:
                            scores.leftScore.indexOf("DN") > -1 ||
                            scores.leftScore.indexOf("A/S") > -1 ||
                            scores.leftScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_FIRSTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.first_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                      data.push({
                        name: "AWAY_LASTNAME_" + row,
                        value: {
                          text:
                            match.players?.find((p) => p.order === 2)?.player
                              ?.last_name || "",
                          colour:
                            scores.rightScore.indexOf("DN") > -1 ||
                            scores.rightScore.indexOf("A/S") > -1 ||
                            scores.rightScore === ""
                              ? "#FFFFFF"
                              : "#041E42",
                        },
                      });
                    });
                  preview("GOLF-FF-TABLE-X1", 50, data);
                }}
              >
                FINAL LB
              </Button>
            </Buttons>
          </Column>
        </Route>
      </Switch>
    </Main>
  );
}

export default App;
